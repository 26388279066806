import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

import { Table, Tag, Modal, Button, Space, Input, Drawer } from 'antd';
import {
	CheckOutlined,
	CloseOutlined,
	SearchOutlined
} from '@ant-design/icons';


//import TranslateModal from './TranslateModal';
import Highlighter from "react-highlight-words";
import RoleAdd from './RoleAdd';
//import TranslateAdd from './TranslateAdd';
export class RoleContainer extends Component {


	constructor(props) {
		super(props);
		this.state = {
			pagination: {
				current: 1,
				pageSize: 10,
				total: 0
			},

			tableData: [],

			loading: true,
			openDrawer: false,
			id: 0,

			columns: [
				{
					title: '#',
					dataIndex: 'key',
					key: 'key',
					sorter: true,
				},
				{
					title: 'id',
					dataIndex: 'id',
					key: 'id',
					sorter: true,
				},
				{
					title: 'Role',
					dataIndex: 'role',
					key: 'role',
				},

				{
					title: 'Role title',
					dataIndex: 'roleName',
					key: 'roleName',
				},
				{
					title: 'Company Type',
					dataIndex: 'companyType',
					key: 'companyType',
				},
				{
					title: 'agencyType',
					dataIndex: 'agencyType',
					key: 'agencyType',



				},

				{
					title: 'Action',
					dataIndex: 'lastName',
					key: 'lastName',
					render: (text, record) => (
						<>
							<Tag onClick={this.openDrawer.bind(this, record.id)} style={{ cursor: "pointer", marginBottom: "5px" }} icon={<CloseOutlined />} color="magenta">Delete</Tag>
							<Tag onClick={this.openDrawer.bind(this, record.id)} style={{ cursor: "pointer", marginBottom: "5px" }} icon={<CheckOutlined />} color="blue">Edit</Tag>
						</>
					)
				},

			]
		}
	}





	refresh = () => {
		this.setState({
			modalRecord: [],
			modalItem: [],
			isModalOpen: false,
			openDrawer: false
		}, () => {
			this.getData();
		});
	}



	openDrawer = (id) => {

		this.setState({
			id: id,
			openDrawer: true
		});

	}


	componentDidMount() {
		this.getData();
	}

	getData = async () => {

		this.setState({
			loading: true,
		});

		try {
			const items = {
				pagination: this.state.pagination,
				sorters: this.state.sorters,
				filterTable: this.state.filterTable
			}
			const res = await axios.post(process.env.REACT_APP_ADDRESS + `Definition_Role/getRole`, items);
			const result = res.data;
			if (!result.status) {
				this.setState({
					loading: false,
					auth: false
				})

			} else {
				this.setState({
					loading: false,
					tableData: result.data,
					pagination: {
						...this.state.pagination, total: result.total
					}
				})
			}

		} catch (e) {
			console.log(e);
		}
	}

	closeDrawer = () => {
		this.setState({
			id: 0,
			openDrawer: false
		});
	}

	render() {

		return (
			<div className='row'>
				<div className='col-md-12 mb-4 text-end'>
					<button className='btn btn-primary' onClick={() => this.openDrawer(0)}>Add Role</button>
				</div>
				<div className='col-md-12'>
					<Table
						columns={this.state.columns}
						dataSource={this.state.tableData}
						rowKey={"key"}
						scroll={{ x: 'auto' }}
						pagination={this.state.pagination}
						loading={this.state.loading}
					/>

					<Drawer
						title={"Add Role item"}
						width={720}
						onClose={this.closeDrawer.bind(this)}
						open={this.state.openDrawer}
						bodyStyle={{ paddingBottom: 80 }}

					>

						{this.state.openDrawer &&
							<>
								{
									<RoleAdd id={this.state.id}/>

								}
							</>
						}

					</Drawer>


				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(RoleContainer)