import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { ParseUserAgent } from "../../../Libs/Devices/ParseUserAgent"
import { WindowSize } from "../../../Libs/Devices/WindowSize"

import { storageSet,storageGet } from '../../../Libs/Collector/StorageAction'
import { cookieSet } from '../../../Libs/Collector/CookieAction'



export const getApps = createAsyncThunk(
    'Apps/getApps',
    async (data) => {

        const apiData = {
            browserName: ParseUserAgent.browserName,
            deviceType: ParseUserAgent.deviceType,
            fullBrowserVersion: ParseUserAgent.fullBrowserVersion,
            getUA: ParseUserAgent.getUA,
            mobileModel: ParseUserAgent.mobileModel,
            mobileVendor: ParseUserAgent.mobileVendor,
            osName: ParseUserAgent.osName,
            osVersion: ParseUserAgent.osVersion,
            language: storageGet("locale"),
            windowSize: WindowSize(),
            clientId: data.clientId.key,
            sessionId: data.sessionId.key,
            sId: data.sId
        }

        const res = await axios.post(process.env.REACT_APP_ADDRESS + `Init/InitApp`, apiData);


        return res.data;
    })


export const AppSlice = createSlice({
    name: 'Apps',
    initialState: {
        loading: true,
        locales: [],
        companyTypes:[],
        translate:[],


    },
    extraReducers: (builder) => {


        builder.addCase(getApps.pending, (state, { payload }) => {
            state.loading = true
        })

        builder.addCase(getApps.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.locales = payload.locale
            state.companyTypes = payload.companyTypes
            state.translate = payload.translate

            const sid = {
                key: payload.sId
            }
            storageSet("sId", sid);
            cookieSet("csrf", payload.csrf);

            axios.defaults.headers.common["sId"] = payload.sId;
            axios.defaults.headers.common["csrf"] = payload.csrf;
            axios.defaults.headers.common["clientId"] = payload.clientId;
            axios.defaults.headers.common["sessionId"] = payload.sessionId;
            axios.defaults.headers.common["clientlanguage"] = storageGet('locale');

        })


        builder.addCase(getApps.rejected, (state, { payload }) => {
            state.loading = false
        })

    }
})

export const AppReducer = AppSlice.reducer