import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios';
import { Divider, Skeleton, notification } from 'antd';

import update from 'immutability-helper';


export class ItemAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            code: "",
            data: [],
        }
    }

    onChange = (i,e) => {

      

        let newItem = update(this.state.data, {
                [i]: i => update(i, {
                    [e.target.name]: {
                        $set: parseInt(e.target.value)
                    },
                })
            });

            this.setState({
                data: newItem
            })
       
    }


    componentDidMount() {

        this.getData();

    }


    getData = async () => {
        this.setState({
            loading: true,
        });

        const data = {
            id: this.props.id,
        }

        try {

            const res = await axios.post(process.env.REACT_APP_ADDRESS + `Definition_Policy/getPolicy`, data);
            const result = res.data;

            if (result.status) {

                this.setState({
                    loading: false,
                    data: result.data
                })


            }

        } catch (e) {
            console.log(e);
        }
    }


    addConditions = () => {

        const data = {
            id: 0,
            startTime: 0,
            endTime: 0,
            parentId: this.props.id,
            policyType: "cancel",
            multipler: 0
        }

        const newData = update(this.state.data, {
            $push: [data]
        });

        this.setState({
            data: newData
        })
    }



    removeItem = (index) => {
        let newItem = update(this.state.data, {
            $splice: [[index, 1]]
        })

        this.setState({
            data: newItem
        })
    }


    updateCondations = async () => {
        this.setState({
            loading: true,
        });

        const data = {
            id: this.props.id,
            data:this.state.data,

        }

        try {

            const res = await axios.post(process.env.REACT_APP_ADDRESS + `Definition_Policy/setConditions`, data);
            const result = res.data;

            if (result.status) {

                this.setState({
                    loading: false,
                });
                this.props.refresh();

            }

        } catch (e) {
            console.log(e);
        }
    }




    render() {

        console.log(this.state);
        return (

            <>
                {
                    this.state.loading ? <Skeleton /> :
                        <>
                            {this.state.data.map((item, i) => (
                                <div className='row align-items-center mb-3' >
                                    <div className='col-md-1'>
                                        <h6 className='mb-0'>{i + 1}.</h6>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-floating ">
                                            <input
                                                type="text"
                                                maxLength="50"
                                                placeholder="Start Time"
                                                required
                                                className="form-control"
                                                onChange={this.onChange.bind(this,i)}
                                                defaultValue={item.startTime}
                                                name="startTime"
                                                id="startTime" />
                                            <label htmlFor="startTime">Start time</label>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                name="endTime"
                                                maxLength="50"
                                                onChange={this.onChange.bind(this,i)}
                                                placeholder="End Time"
                                                defaultValue={item.endTime}
                                                className="form-control"
                                                id="endTime" />
                                            <label htmlFor="endTime">End time</label>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                name="multipler"
                                                maxLength="50"
                                                onChange={this.onChange.bind(this,i)}
                                                placeholder="Percent"
                                                defaultValue={item.multipler}
                                                className="form-control"
                                                id="multipler" />
                                            <label htmlFor="multipler">Refund Percent</label>
                                        </div>
                                    </div>

                                    {this.state.data.length === i + 1 &&

                                        <div className='col-md-1'>
                                            <button className='btn btn-danger btn-lg'
                                                disabled={this.state.loading ? true : false}
                                                onClick={() => this.removeItem(i)}>-</button>
                                        </div>
                                    }

                                </div>
                            ))}

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <button type="button" onClick={this.addConditions} className='btn btn-warning btn-sm'>Add condition</button></div>
                            </div>

                            <Divider></Divider>
                            <div className='row mt-4'>
                                <div className='col-md-12 text-end'>
                                    <button type="button" onClick={this.updateCondations} className='btn btn-primary'>Update Policy</button></div>
                            </div>
                        </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ItemAdd)