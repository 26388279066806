import { Loader } from '@googlemaps/js-api-loader';


export const LoadScript = async () => {

    const loader = new Loader({
        apiKey: process.env.REACT_APP_GOGOLE_MAP_API,
        version: "weekly",
        libraries: ["places", "geometry", "drawing"]
    });

    loader
        .load()
        .then((google) => {
  
            if (google) {
                return true;

            } else {
                return false;
            }
        })
        .catch(e => {
            console.log(e);
            return false;
        });

}