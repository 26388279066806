import React, { Component, Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import { v4 as uuid } from 'uuid';
import { storageGet, storageSet } from '../../../Libs/Collector/StorageAction'
import { sessionGet, sessionSet } from '../../../Libs/Collector/SessionAction'
import { LoadScript } from '../../../Libs/Services/GoogleLoader'

import { getApps } from '../Reducer/AppReducer'
import { Language } from '../../../Libs/Devices/Language'
import PrivateRoute from '../../../Libs/Auth/PrivateRoutes'
import Layouts from '../../Layout/Component/Layout';

import Role from '../../../Pages/Definitions/Role';
import MailList from '../../Definitions/MailTemplate/MailList';
import Policy from '../../../Pages/Definitions/Policy';








const Login = React.lazy(() => import('../../../Pages/Login'));
const Dashboard = React.lazy(() => import('../../../Pages/Dashboard'));
const Translate = React.lazy(() => import('../../../Pages/Definitions/Translate'));
const Categories = React.lazy(() => import('../../../Pages/Definitions/Categories'));
const Features = React.lazy(() => import('../../../Pages/Definitions/Features'));
const GuestInformation = React.lazy(() => import('../../../Pages/Definitions/GuestInformation'));
const PriceInfo = React.lazy(() => import('../../../Pages/Definitions/PriceInfo'));
const PriceItem = React.lazy(() => import('../../../Pages/Definitions/PriceItem'));

const PriceQuantity = React.lazy(() => import('../../../Pages/Definitions/PriceQuantity'));
const Review = React.lazy(() => import('../../../Pages/Definitions/Review'));

const PackageAmenites = React.lazy(() => import('../../../Pages/Definitions/PackageAmenites'));

const Languages = React.lazy(() => import('../../../Pages/Definitions/Languages'));
const Currency = React.lazy(() => import('../../../Pages/Definitions/Currency'));
const Location = React.lazy(() => import('../../../Pages/Definitions/Location'));
const LocationTranslate = React.lazy(() => import('../../../Pages/Definitions/LocationTranslate'));

const Partners = React.lazy(() => import('../../../Pages/Partners/Partners'));
const Experience = React.lazy(() => import('../../../Pages/Product/Experience'));

export class App extends Component {

	constructor(props) {
		super(props);
		this.state = {

		}
	}

	componentDidMount() {
		this.init();
	}

	init = () => {
		LoadScript();


		if (Object.keys(storageGet("clientId")).length === 0 && storageGet("clientId").constructor === Object) {
			const datas = {
				key: uuid()
			}
			storageSet("clientId", datas);
		}


		if (Object.keys(storageGet("locale")).length === 0 && storageGet("locale").constructor === Object) {

			storageSet("locale", Language()[0]);

		}

		if (Object.keys(sessionGet("sessionId")).length === 0 && sessionGet("sessionId").constructor === Object) {

			const datas = {
				key: uuid()
			}
			sessionSet("sessionId", datas);

		}


		if (Object.keys(storageGet("clientId")).length !== 0 &&
			Object.keys(sessionGet("sessionId")).length !== 0
		) {


			const datas = {
				clientId: storageGet("clientId"),
				sessionId: sessionGet("sessionId"),
				sId: Object.keys(storageGet("sId")).length !== 0 ? storageGet("sId") : "",
			}
			this.props.getApps(datas);

		}

	}


	render() {

		const { loading } = this.props.Apps

		return (
			<>
				{!loading &&

					<BrowserRouter>
						<Routes>




							<Route exact element={<PrivateRoute />}>

								<Route exact path="/Dashboard" element={
									<Layouts>
										<Suspense fallback={<div>Loading...</div>}>
											<Dashboard />
										</Suspense>
									</Layouts>} />

								<Route exact path="/Definitions/Translate" element={
									<Layouts>
										<Suspense fallback={<div>Loading...</div>}>
											<Translate />
										</Suspense>
									</Layouts>} />
								<Route exact path="/Definitions/Categories" element={
									<Layouts>
										<Suspense fallback={<div>Loading...</div>}>
											<Categories />
										</Suspense>
									</Layouts>} />

								<Route exact path="/Definitions/Features" element={
									<Layouts>
										<Suspense fallback={<div>Loading...</div>}>
											<Features />
										</Suspense>
									</Layouts>} />
								<Route exact path="/Definitions/GuestInformation" element={
									<Layouts>
										<Suspense fallback={<div>Loading...</div>}>
											<GuestInformation />
										</Suspense>
									</Layouts>} />

								<Route exact path="/Definitions/PriceInfo" element={
									<Layouts>
										<Suspense fallback={<div>Loading...</div>}>
											<PriceInfo />
										</Suspense>
									</Layouts>} />


								<Route exact path="/Definitions/PriceItem" element={
									<Layouts>
										<Suspense fallback={<div>Loading...</div>}>
											<PriceItem />
										</Suspense>
									</Layouts>} />


								<Route exact path="/Definitions/Policy" element={
									<Layouts>
										<Suspense fallback={<div>Loading...</div>}>
											<Policy />
										</Suspense>
									</Layouts>} />


								<Route exact path="/Definitions/PriceQuantity" element={
									<Layouts>
										<Suspense fallback={<div>Loading...</div>}>
											<PriceQuantity />
										</Suspense>
									</Layouts>} />


								<Route exact path="/Definitions/Review" element={
									<Layouts>
										<Suspense fallback={<div>Loading...</div>}>
											<Review />
										</Suspense>
									</Layouts>} />

								<Route exact path="/Definitions/PackageAmenites" element={
									<Layouts>
										<Suspense fallback={<div>Loading...</div>}>
											<PackageAmenites />
										</Suspense>
									</Layouts>} />


								<Route exact path="/Definitions/Languages" element={
									<Layouts>
										<Suspense fallback={<div>Loading...</div>}>
											<Languages />
										</Suspense>
									</Layouts>} />

								<Route exact path="/Definitions/Currency" element={
									<Layouts>
										<Suspense fallback={<div>Loading...</div>}>
											<Currency />
										</Suspense>
									</Layouts>} />

								<Route exact path="/Definitions/Location" element={
									<Layouts>
										<Suspense fallback={<div>Loading...</div>}>
											<Location />
										</Suspense>
									</Layouts>} />


								<Route exact path="/Definitions/LocationTranslate" element={
									<Layouts>
										<Suspense fallback={<div>Loading...</div>}>
											<LocationTranslate />
										</Suspense>
									</Layouts>} />

								<Route exact path="/Definitions/Role" element={
									<Layouts>
										<Suspense fallback={<div>Loading...</div>}>
											<Role />
										</Suspense>
									</Layouts>} />


								<Route exact path="/Definitions/MailTemplate" element={
									<Layouts>
										<Suspense fallback={<div>Loading...</div>}>
											<MailList />
										</Suspense>
									</Layouts>} />

								<Route exact path="/Partners" element={
									<Layouts>
										<Suspense fallback={<div>Loading...</div>}>
											<Partners />
										</Suspense>
									</Layouts>} />
								<Route exact path="/ProductList" element={
									<Layouts>
										<Suspense fallback={<div>Loading...</div>}>
											<Experience />
										</Suspense>
									</Layouts>} />

							</Route>


							<Route path='/' element={
								<Suspense fallback={<div>Loading...</div>}>
									<Login />
								</Suspense>
							} />

							<Route path='/Login' element={
								<Suspense fallback={<div>Loading...</div>}>
									<Login />
								</Suspense>} />

						</Routes>
					</BrowserRouter>

				}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	Apps: state.Apps
})

const mapDispatchToProps = {
	getApps
}

export default connect(mapStateToProps, mapDispatchToProps)(App)