import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios';
import { Table, Drawer, Switch } from 'antd';
import ItemAdd from './ItemAdd';

import { MenuOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
	arrayMove,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import LoadingContent from '../../../Libs/Helpers/LoadingContent'



export class PriceItemContainer extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			id: 0,
			title:"",
			data: [],
			isModalOpen: false,
			categoriesId: 0,
			status: false,
			columns: [
				{
					key: 'sort',
				},
				{
					title: '#',
					dataIndex: 'key',
					key: 'key',
					sorter: true,
				},
				{
					title: 'Product yype',
					dataIndex: 'productType',
					key: 'productType',
				},
				{
					title: 'Title',
					dataIndex: 'title',
					key: 'title',
				},

				{
					title: 'Status',
					dataIndex: 'status',
					key: 'status',
					render: (text, record) => (
						<>
							<Switch defaultChecked={text} onChange={this.setStatus.bind(this, record.id)} />
						</>
					)
				},
				{
					title: 'Action',
					dataIndex: 'id',
					key: 'id',
					render: (text, row) => (
						<>
							<button type="button" onClick={this.editItem.bind(this,text, row.title)} className='btn btn-primary'>Edit</button>
						</>
					)
				}

			]

		}
	}



	componentDidMount() {
		this.getData();
	}

	getData = async () => {
		this.setState({
			loading: true,
		});

		try {

			const res = await axios.post(process.env.REACT_APP_ADDRESS + `Definition_Policy/getData`);
			const result = res.data;
			if (!result.status) {
				this.setState({
					loading: false,
					auth: false
				})

			} else {
				this.setState({
					loading: false,
					data: result.data,

				})
			}

		} catch (e) {
			console.log(e);
		}
	}

	openDrawer = () => {
		this.setState({
			openDrawer: true
		})
	}

	closeDrawer = () => {
		this.setState({
			openDrawer: false
		})
	}

	getRefresh = () => {
		this.getData();
		this.closeDrawer()
	}



	setStatus = async (id, e) => {
		this.setState({
			loading: true,
		});


		let data = {
			id: id,
			status: e ? 1 : 0

		}

		try {

			const res = await axios.post(process.env.REACT_APP_ADDRESS + `Definition_PriceItem/setStatus`, data);
			const result = res.data;
			if (!result.status) {
				this.setState({
					loading: false,
					auth: false
				})

			} else {
				this.setState({
					loading: false,
				}, () => {
					this.getData();
				})
			}

		} catch (e) {
			console.log(e);
		}
	}

	onDragEnd = ({ active, over }) => {
		if (active.id !== over?.id) {
			let previous = this.state.data;
			const activeIndex = previous.findIndex((i) => i.key === active.id);
			const overIndex = previous.findIndex((i) => i.key === over?.id);
			this.setState({
				data: arrayMove(previous, activeIndex, overIndex)
			}, () => {
				this.setOrdered();
			})

		}
	}


	setOrdered = async () => {

		const data = {
			data: this.state.data
		}

		this.setState({
			loading: true,
		});

		try {

			const res = await axios.post(process.env.REACT_APP_ADDRESS + `Definition_Policy/setOrdered`, data);
			const result = res.data;
			if (!result.status) {
				this.setState({
					loading: false,
					auth: false
				})

			} else {
				this.setState({
					loading: false,
				}, () => {
					this.getData();
				})
			}

		} catch (e) {
			console.log(e);
		}
	}

	Row = ({ children, ...props }) => {
		const {
			attributes,
			listeners,
			setNodeRef,
			setActivatorNodeRef,
			transform,
			transition,
			isDragging,
		} = useSortable({
			id: props['data-row-key'],
		});


		const style = {
			...props.style,
			transform: CSS.Transform.toString(
				transform && {
					...transform,
					scaleY: 1,
				},
			),
			transition,
			...(isDragging
				? {
					position: 'relative',
					zIndex: 9999,
				}
				: {}),
		};
		return (
			<tr {...props} ref={setNodeRef} style={style} {...attributes}>
				{React.Children.map(children, (child) => {
					if (child.key === 'sort') {
						return React.cloneElement(child, {
							children: (
								<MenuOutlined
									ref={setActivatorNodeRef}
									style={{
										touchAction: 'none',
										cursor: 'move',
									}}
									{...listeners}
								/>
							),
						});
					}
					return child;
				})}
			</tr>
		);
	}


	editItem = (id,title) => {
		this.setState({
			id: id,
			title:title,
			openDrawer: true
		})
	}




	render() {

		return (
			<>
				{this.state.loading && <LoadingContent />}
				<div className='row'>
					<div className='col-md-12 mb-4 text-end'>
						<button className='btn btn-primary' onClick={() => this.openDrawer()}>Add code</button>
					</div>
					<div className='col-md-12'>

					</div>
					<DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={this.onDragEnd}>
						<SortableContext
							items={this.state.data.map((i) => i.key)}
							strategy={verticalListSortingStrategy}
						>
							<Table
								components={{
									body: {
										row: this.Row,
									},
								}}
								pagination={{ pageSize: 50 }}
								columns={this.state.columns}
								dataSource={this.state.data}
							/>
						</SortableContext>
					</DndContext>
					<Drawer
						title={this.state.title + " item"}
						width={720}
						onClose={this.closeDrawer.bind(this)}
						open={this.state.openDrawer}
						bodyStyle={{ paddingBottom: 80 }}

					>

						{this.state.openDrawer &&
							<>
								{
									<ItemAdd
										id={this.state.id}
										refresh={this.getRefresh}
									/>
								}
							</>
						}

					</Drawer>



				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PriceItemContainer)