import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios';
import { Divider, notification, Skeleton, Tree } from 'antd';

export class RoleAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            id: props.id,
            role: "",
            roleTitle: "",
            selectedAgency: "0",
            selectedCompany: "1",
            agencyTypes: [],
            companyType: [],
            treeData: [],
            selectedModule: []
        }
    }


    getDefinitions = async () => {
        try {

            const res = await axios.post(process.env.REACT_APP_ADDRESS + `Definition_Role/getDefinitions`);
            const result = res.data;
            if (result.status) {



                this.setState({
                    agencyTypes: result.data.agencyTypes,
                    companyType: result.data.companyType,
                    treeData: result.data.modules

                }, () => {
                    if (this.state.id !== 0) {

                        this.getOldData();
                    }
                })
            }


        } catch (e) {
            console.log(e);
        }
    }


    getOldData = async () => {
        try {

            const data = {
                id: this.state.id
            }

            this.setState({
                loading:true,
            });

            const res = await axios.post(process.env.REACT_APP_ADDRESS + `Definition_Role/getOldData`, data);
            const result = res.data;
            if (result.status) {
                this.setState({
                    selectedAgency: String(result.data.agencyType),
                    selectedCompany: String(result.data.companyType),
                    role: result.data.role,
                    roleTitle: result.data.roleName,
                    loading:false,
                });

            }


        } catch (e) {
            console.log(e);
        }
    }

    componentDidMount() {

        this.getDefinitions();

    }


    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    setCode = async () => {
        this.setState({
            loading: true,
        });

        const data = {
            id: this.state.id,
            role: this.state.role,
            roleName: this.state.roleTitle,
            companyType: this.state.selectedCompany,
            agencyType: this.state.selectedAgency,
            rule: this.state.selectedModule

        }

        try {

            const res = await axios.post(process.env.REACT_APP_ADDRESS + `Definition_Role/setRole`, data);
            const result = res.data;

            console.log(result)
            this.setState({
                loading: false,
            });

        } catch (e) {

            console.log(e);
        }
    }
    onCheck = (selectedKeys, info) => {


        this.setState({
            selectedModule: selectedKeys
        })
    }


    render() {


        return (
            <>
                {this.state.loading ? <Skeleton /> :
                    <>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        name="role"
                                        maxLength="50"
                                        onChange={this.onChange}
                                        placeholder="role"
                                        required
                                        className="form-control"
                                        value={this.state.role}
                                        id="role" />
                                    <label htmlFor="role">Role Code</label>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        name="roleTitle"
                                        maxLength="50"
                                        onChange={this.onChange}
                                        placeholder="roleTitle"
                                        value={this.state.roleTitle}
                                        required
                                        className="form-control"
                                        id="roleTitle" />
                                    <label htmlFor="role">Role Title</label>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 mb-3'>
                                <div className="form-floating mb-3">
                                    <select
                                        name="selectedCompany"
                                        className="form-select"
                                        id="status"
                                        value={this.state.selectedCompany}
                                        onChange={this.onChange}
                                    >

                                        {this.state.companyType.map((item, i) => (
                                            <option key={i} value={item.id}>{item.name}</option>
                                        ))}



                                    </select>
                                    <label htmlFor="companyType">Company Type</label>
                                </div>
                            </div>
                            {this.state.selectedCompany === "2" &&
                                <div className='col-md-6 mb-3'>
                                    <div className="form-floating mb-3">
                                        <select
                                            name="selectedAgency"
                                            className="form-select"
                                            id="agencyType"
                                            value={this.state.selectedAgency}
                                            onChange={this.onChange}
                                        >

                                            {this.state.agencyTypes.map((item, i) => (
                                                <option key={i} value={item.id}>{item.agencyType}</option>
                                            ))}

                                        </select>
                                        <label htmlFor="agencyType">Agency Type</label>
                                    </div>
                                </div>
                            }
                        </div>
                        <Divider />
                        <div className='row mb-3'>
                            <div className='col-md-12'>
                                <Tree

                                    checkable
                                    onCheck={this.onCheck}
                                    treeData={this.state.treeData}
                                />
                            </div>
                        </div>
                        <Divider />
                        <div className='row'>
                            <div className='col-md-12'>
                                <button className='btn btn-primary'
                                    disabled={this.state.loading ? true : false}
                                    onClick={() => this.setCode()}>Add code</button>
                            </div>
                        </div>
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(RoleAdd)