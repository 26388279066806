import React from 'react'
import { connect } from 'react-redux'
import { Breadcrumb, Card } from 'antd';

import RoleContainer from "../../Components/Definitions/Role/RoleContainer"

export const Role = (props) => {
    return (

        <div className='app-body dashboard'>
            <div className='row mb-4'>
                <div className='col-md-12'>
                    <Breadcrumb>
                        <Breadcrumb.Item>Definitions</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Role
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>

            <div className='row mb-3'>

                <div className='col-md-12'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Card>
                                <RoleContainer/>
                            </Card>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Role)