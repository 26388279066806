import React from 'react'
import { connect } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
export const LoadingContent = (props) => {
    return (
        <div className='loadingContent' style={{margin:"-20px"}}>
            <div className='loadIcon'><Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /></div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LoadingContent)