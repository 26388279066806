import React, { Component } from 'react'
import { connect } from 'react-redux'


import axios from "axios";
import { Breadcrumb, Table, Tag, Progress, Dropdown, Menu, Modal, Drawer } from 'antd';


import {
	CheckOutlined,
	CloseOutlined,
	SearchOutlined
} from '@ant-design/icons';
import MailEditor from './MailEditor';
// theme, If you need to change the theme, you can make a duplicate in https://arco.design/themes/design/1799/setting/base/Color


export class MailList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
            pagination: {
				current: 1,
				pageSize: 10,
				total: 0
			},

			tableData: [],

			loading: true,
			openDrawer: false,
            columns: [
                {
                    title: '#',
                    dataIndex: 'key',
                    key: 'key',
                    sorter: true,
                },
                {
                    title: 'Template Code',
                    dataIndex: 'templateCode',
                    key: 'templateCode',
                },

                {
                    title: 'Template Name',
                    dataIndex: 'templateName',
                    key: 'templateName',
                },
                {
                    title: 'Action',
                    dataIndex: 'lastName',
                    key: 'lastName',
                    render: (text, record) => (
                        <>
                            <Tag onClick={this.openDrawer.bind(this, record.id)} style={{ cursor: "pointer", marginBottom: "5px" }} icon={<CloseOutlined />} color="magenta">Delete</Tag>
                            <Tag onClick={this.openDrawer.bind(this, record.id)} style={{ cursor: "pointer", marginBottom: "5px" }} icon={<CheckOutlined />} color="blue">Edit</Tag>
                        </>
                    )
                },

            ]
        }
    }

    componentDidMount() {
		this.getData();
	}

    openDrawer = (id) => {

		this.setState({
			id: id,
			openDrawer: true
		});

	}


    closeDrawer = () => {
		this.setState({
			id: 0,
			openDrawer: false
		});
	}


    getData = async () => {

		this.setState({
			loading: true,
		});

		try {
			const items = {
				pagination: this.state.pagination,
				sorters: this.state.sorters,
				filterTable: this.state.filterTable
			}
			const res = await axios.post(process.env.REACT_APP_ADDRESS + `Definition_Template/getTemplates`, items);
			const result = res.data;
			if (!result.status) {
				this.setState({
					loading: false,
					auth: false
				})

			} else {
				this.setState({
					loading: false,
					tableData: result.data,
					pagination: {
						...this.state.pagination, total: result.total
					}
				})
			}

		} catch (e) {
			console.log(e);
		}
	}

    render() {



        return (
            <>

            <div className='row'>
				<div className='col-md-12 mb-4 text-end'>
					<button className='btn btn-primary' onClick={() => this.openDrawer(0)}>Add Role</button>
				</div>
				<div className='col-md-12'>
					<Table
						columns={this.state.columns}
						dataSource={this.state.tableData}
						rowKey={"key"}
						scroll={{ x: 'auto' }}
						pagination={this.state.pagination}
						loading={this.state.loading}
					/>

					<Drawer
						title={"Mail Editor"}
						width={"100%"}
						onClose={this.closeDrawer.bind(this)}
                        keyboard={false}
						open={this.state.openDrawer}
						bodyStyle={{ padding:0 }}

					>

						{this.state.openDrawer &&
							<>
								{
								<MailEditor/>	

								}
							</>
						}

					</Drawer>


				</div>
			</div>
         {/*      <div>
             

<EmailEditorProvider
      data={this.state.initialValues}
      height={'calc(100vh - 72px)'}
      autoComplete
      dashed={false}
    >
      {({ values }) => {
        return (
          <StandardLayout
            
            showSourceCode={true}
          >
            <EmailEditor />
          </StandardLayout>
        );
      }}
    </EmailEditorProvider>
    

            </div>*/}
            </>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MailList)