import React, { Component } from 'react'
import { connect } from 'react-redux'

import { BlockManager, BasicType, AdvancedType } from 'easy-email-core';
import { EmailEditor, EmailEditorProvider } from 'easy-email-editor';
import { ExtensionProps, StandardLayout } from 'easy-email-extensions';
import { useWindowSize } from 'react-use';
import 'easy-email-editor/lib/style.css';
import 'easy-email-extensions/lib/style.css';
import '@arco-themes/react-easy-email-theme/css/arco.css';
export class MailEditor extends Component {

    constructor(props) {
        super(props);
        this.state={
            initialValues: {
                subject: 'Welcome to Tourzone',
                subTitle: 'Nice to meet you!',
                content: BlockManager.getBlockByType(BasicType.PAGE).create({}),
            },
        }
    }
  render() {
    return (
        <EmailEditorProvider
        data={this.state.initialValues}
        height={'calc(100vh - 72px)'}
        autoComplete
        dashed={false}
      >
        {({ values }) => {
          return (
            <StandardLayout
              
              showSourceCode={true}
            >
              <EmailEditor />
            </StandardLayout>
          );
        }}
      </EmailEditorProvider>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MailEditor)
