import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import App from './Components/App/Component/App';
import { Provider } from "react-redux";
import store from "./Redux/store";

import { injectStore, getRefreshPageTokenSet, setAuthorizationToken } from "./Libs/Auth/setAuthToken"
injectStore(store);

const token = localStorage.getItem("token");

if (token) {
  getRefreshPageTokenSet();
  setAuthorizationToken(token)
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
