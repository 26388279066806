import React from 'react'
import { connect } from 'react-redux'
import { Outlet, Navigate } from 'react-router-dom'






function PrivateRoutes(props) {
    return (
        <>
            {(props.Login.auth && !props.Login.loading) ? <Outlet /> : <Navigate to="/Login" />}
        </>

    )

}


const mapStateToProps = (state) => ({
    Login:state.Login
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoutes);

